.overlay {
  width: 0;
  height: auto;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(3, 39, 91, 0.9);
  overflow-x: hidden;
  transition: 0.5s; }
  .overlay .closebtn {
    position: absolute;
    top: 50px;
    right: 10px;
    font-size: 4.0rem; }
    @media (min-width: 992px) {
      .overlay .closebtn {
        top: 0;
        right: 45px;
        font-size: 6.0rem; } }
  .overlay .overlay-content {
    position: relative;
    top: 0;
    width: 90%;
    text-align: left;
    padding: 80px 0px 50px 0px; }
    @media (min-width: 768px) {
      .overlay .overlay-content {
        padding: 50px 30px 50px 130px;
        width: 80%; } }
    .overlay .overlay-content a {
      color: #fff;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700; }
    .overlay .overlay-content .home {
      padding-left: 13px;
      display: block;
      padding-bottom: 11px; }
  .overlay .card {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    color: #fff; }
    .overlay .card ul li {
      font-size: 1.8rem; }
      .overlay .card ul li a {
        color: #b3b2b2;
        font-size: 1.4rem;
        font-weight: 400; }
      .overlay .card ul li ul {
        padding-left: 15px; }
        .overlay .card ul li ul li {
          padding-bottom: 5px; }
    .overlay .card .card-header {
      border: 0;
      text-transform: uppercase;
      font-size: 2.4rem;
      font-weight: 700; }
      .overlay .card .card-header a {
        color: #fff; }
